import React from "react";
import { graphql } from "gatsby";
import moment from "moment"
import Layout from "../components/layout";
import BlockContent from "@sanity/block-content-to-react";
import SEO from "../components/seo";
import serializers from "../helper/textSerializer";
import CaptionedImage from "../components/captioned-image";
import ContentTile from "../components/content-tile";
import AccordionModule from "../components/accordion-module";

export const query = graphql`
  query NewsTemplateQuery($id: String!) {
    article: sanityNews(id: { eq: $id }) {
      id
      title
      publishedDate
      image {
        image {
          asset {
            _id
            altText
          }
        }
        credit
        caption
      }
      tileDescription
      _rawContent(resolveReferences: { maxDepth: 8 })
      tags {
        value
      }
      accordionModule {
        _key
        _type
        heading
        items {
          heading
          _rawBody(resolveReferences: { maxDepth: 8 })
        }
        buttonText
        buttonLink {
          landingPageRoute {
            ... on SanityPage {
              id
              slug {
                current
              }
              path
            }
            ... on SanityNewsPage {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityNews {
              id
              _type
              slug {
                current
              }
              path
            }
            ... on SanityEventsPage {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityContactPage {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityEvents {
              id
              _type
              slug {
                current
              }
              path
            }
          }
          link
          route
          pdf {
            asset {
              url
            }
          }
        }
      }
      relatedPosts {
        id
        _type
        title
        tags {
          value
        }
        slug {
          current
        }
        path
        _rawContent
        image {
          image {
            asset {
              _id
              altText
            }
          }
        }
        publishedDate
        tileDescription
        tags {
          value
        }
      }
      pageSEO {
        metaDescription
        metaTitle
        metaImage {
          asset {
            _id
            altText
          }
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

const NewsTemplate = props => {
  const { data } = props;
  const article = data && data.article;
  const relatedPosts = data.article.relatedPosts;

  return (
    <Layout className="article">
      <SEO
        seo={article.pageSEO}
        title={article.title}
        image={article.image?.image}
        excerpt={article.tileDescription}
      />
      <div className="news-page page">
        <section className="article">
          <div className="article-hero">
            {article.tags && (
              <p className="tags-container subtitle-two">
                <span>{`${article.tags.map(x => x.value).join(" / ")}`}</span>
              </p>
            )}
            {article.publishedDate && (
              <p className="article-date subtitle-one">
                {moment(article.publishedDate).format('LL')}
              </p>
            )}
            {article.title && <h1>{article.title}</h1>}
            {article.image && article.image.image && (
              <CaptionedImage image={article.image} type="hero" />
            )}
          </div>
          {article._rawContent && article._rawContent[0] && (
            <div className="article-content">
              <BlockContent
                blocks={article._rawContent}
                className="serialized-content"
                renderContainerOnSingleChild={true}
                serializers={{
                  types: {
                    block: serializers.types.block,
                    undefined: serializers.undefined,
                    image: serializers.image,
                    articleImage: serializers.articleImage,
                    youtube: serializers.youtube,
                    vimeo: serializers.vimeo,
                    articleReference: serializers.articleReference,
                    news: serializers.articleReference,
                  },
                  hardBreak: serializers.hardBreak,
                  list: serializers.list,
                  listItem: serializers.listItem,
                  marks: serializers.marks,
                }}
              />
            </div>
          )}
        </section>
        {article.accordionModule && (
          <AccordionModule accordion={article.accordionModule} />
        )}
        {relatedPosts?.[0] && (
          <section className="content-tile-container">
            <h2 className="module-heading">You may also like</h2>
            <div className="content-tile-wrapper -three">
              {relatedPosts.map((content, i) => {
                return <ContentTile content={content} type="ghost" key={i} />;
              })}
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
};

export default NewsTemplate;
